<template>
  <section ref="homme" class="home theme-with-nav"
    :style="{ background: themeConfig.backgroundColor }"
    :class="`home detect_deviece_${deviceType} state_${deviceType} page_is_${themeConfig.isArabia==1?'ar':'en'}`">
    <component :is="`navModule_4`" :host="host" :themeConfig="themeConfig"
      :domainConfig="domainConfig" @keyWordSearch="handleSearch" @selectMenuItem="handleMenuItem" />

    <div class="search-result-row">
      <div class="search-result-title">
        Search results
      </div>
      <div class="search-result-input-row">
        <input v-model="query" class="large-search-bar" type="text" placeholder="Search..."
          @keyup.enter="search">
        <div class="search-button-row">
          <button class="delete-button" @click="deletesearch" />
          <div class="slish-line" />
          <button class="search-button" @click="search" />
        </div>
      </div>
    </div>

    <van-list v-model="loading" :finished="finished" :finished-text="bomTitle" :offset="20"
      class="main-feed" loading-text="Loading..." :immediate-check="false" @load="onLoad">
      <div class="newlist_row">
        <collectionModule_pc v-for="(item, index) in list" :key="`searchkey_${index}`" :item="item"
          @customClick="goLanding(item.id)" />
      </div>
    </van-list>

    <FootModuleLoader v-show="adjustedItems" :themeConfig="themeConfig" :host="host"
      :deviceType="deviceType" />
  </section>
</template>

<script>
import commonMethods from '@/mixin/commonMethodsPC.js';
import adjustedItemsMixin from '@/mixin/adjustedItemsMixinPC.js';
import AdComponent from '@/pages/components/AdComponent.vue'
import '@/css/pc.scss'
import '@/css/module/search/pc.scss'

export default {
  name: 'SearchMain',
  components: { AdComponent },
  mixins: [commonMethods, adjustedItemsMixin],
  data () {
    return {
      query: '',

      adStatusObserver: null,
      adDomObserver: null,
      adIntersectionObserver: null,
      iframeVisibilityTracker: null,
      adStatus: {} // 用于存储广告的可见状态
    }
  },

  async activated () {
    console.log('Component activated-----------');
    this.initData()
  },
  methods: {
    search () {
      if (this.query.trim() !== '') {
        console.log(this.query);
        this.list = [];
        this.page = 1
        this.getList()
      }
    },
    deletesearch () {
      this.list = []
      this.page = 1
      this.query = ''
    },
    handleSearch (data) {
      console.log(data);
      this.query = data
      // this.isSearchResult = true
      this.list = [];
      this.page = 1
      this.getList()
    },
    handleMenuItem (data) {
      console.log(data);
      const processedData = data.split('.my')[0];
      console.log(processedData);
      // window.location.href = `/${processedData}`;
      const channelMatch = this.$route.params.channel
      if (channelMatch) {
        window.location.href = `/${channelMatch}/${processedData}`; // 带上 channel 跳转
      } else {
        window.location.href = `/${processedData}`; // 没有 channel，直接跳转
      }
    },
    getbigcardConfig (index) {
      const bigCardConfig = this.themeConfig.bigCardConfig
      // 第一个组件使用第一个背景颜色，后续组件使用轮流的背景颜色
      if (index === 0) {
        return bigCardConfig[0];
      } else {
        // console.log(bigCardConfig[(index - 1) % (bigCardConfig.length - 1) + 1]);
        return bigCardConfig[(index - 1) % (bigCardConfig.length - 1) + 1];
      }
    },
    getAdConfig (position) {
      // 假设 `position` 与 `adsense` 数组的索引对应
      return this.adsense[`list_ad_${position}`] || {};
    },
    async initData () {
      this.query = this.$route.query.keyword
      console.log('in search');
      console.log(this.$route.query);
      console.log(this.$route.params);

      // this.getList(this.$route.query);
      this.getList();

      // try {
      //   await this.getList();
      //   console.log('loadList func running');
      //   this.initPage();
      //   this.setupIframeTracking();
      //   this.loadAdSenseScript();  // 重新加载广告脚本
      // } catch (error) {
      //   console.error('An error occurred in getList:', error);
      //   // 可以在这里添加错误处理逻辑
      // }
    },
    initPage () {
      this.$eventrackFb('show_page_news', 'expose', {
        pve_cur: '/h5page/home/',
      });

      window.onload = this.handleWindowLoad;
    },
    removeAdScript () {
      // 移除广告实例
      const ads = document.querySelectorAll('.adsbygoogle');
      ads.forEach(ad => {
        ad.remove();  // 如果是简单的广告，直接移除可能就足够了
      });

      // 重置 window.adsbygoogle 数组
      window.adsbygoogle = [];

      // 移除广告脚本标签
      const adScript = document.querySelector('script[src="' + this.adsense?.scriptUrl + '"]');
      if (adScript) {
        adScript.remove();
      }

    },
    disconnectObservers () {
      // 断开广告状态监视器
      if (this.adStatusObserver) {
        this.adStatusObserver.disconnect();
      }

      // 断开广告DOM监视器
      if (this.adDomObserver) {
        this.adDomObserver.disconnect();
      }

      // 断开广告可视状态监视器
      if (this.adIntersectionObserver) {
        this.adIntersectionObserver.disconnect();
      }

      // 可能还需要断开其他由 setupIframeTracking 创建的监听器
      if (this.iframeVisibilityTracker) {
        document.removeEventListener('visibilitychange', this.iframeVisibilityTracker);
      }
    },

    setupIframeTracking () {
      // let _this = this;
      const url = window.location.href
      const iframes = document.querySelectorAll('iframe');
      const tracker = new this.IframeTracker(this);
      iframes.forEach((iframe) => {
        tracker.addIframe(iframe, (iframeElement) => {
          console.log('Iframe 点击触发了!', iframeElement);
          const adContainer = iframeElement.closest('ins.adsbygoogle');
          if (adContainer) {
            tracker.trackEvent('ad_iframe_click', 'click', {
              'data-ad-slot': adContainer.dataset.adSlot,
            });
            this.$logger('ad_iframe_click')
            this.$receiveMsg(url)
          }
        });
      });
    },
    ttTrack (id, type = 'expose', name = 'ad') {
      if (this.host != 'gizmodomno.icu' && this.host != 'techcrunchabc.homes') return;
      console.log('track');
      ttq.track('ClickButton', {
        value: '100', // number. Value of the order or items sold. Example: 100.
        currency: 'USD', // string. The 4217 currency code. Example: "USD".
        contents: [
          {
            content_id: id, // string. ID of the product. Example: "1077218".
            content_type: type, // string. Either product or product_group.
            content_name: name, // string. The name of the page or product. Example: "shirt".
          },
        ],
      });
    },
    IframeTracker: class {
      constructor(vues, resolution = 200) {
        this.vues = vues; // 保存 Vue 实例
        this.resolution = resolution;
        this.iframes = [];
        this.interval = null;
        this.activeIframe = null; // 存储最近被点击的iframe
        this.setupVisibilityTracking();
      }

      addIframe (element, callback) {
        const adContainer = element.closest('ins.adsbygoogle');
        const iframe = {
          element: element,
          callback: callback,
          hasTracked: false,
          adSlot: adContainer ? adContainer.dataset.adSlot : null,
        };
        this.iframes.push(iframe);

        if (!this.interval) {
          this.interval = setInterval(() => this.checkClick(), this.resolution);
        }
      }

      checkClick () {
        const activeElement = document.activeElement;
        if (activeElement) {
          this.iframes.forEach((iframe) => {
            if (activeElement === iframe.element && !iframe.hasTracked) {
              iframe.callback(iframe.element);
              iframe.hasTracked = true;
              this.activeIframe = iframe; // 更新最近被点击的iframe
            }
          });
        }
      }

      setupVisibilityTracking () {
        const hiddenProperty =
          'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null;
        const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');

        const onVisibilityChange = () => {
          if (!document[hiddenProperty]) {
            console.log('页面激活状态,返回页面?');
            this.activeIframe = null; // 重置最近被点击的iframe记录
          } else {
            console.log('页面非激活状态');

            if (this.activeIframe) {
              // 上报逻辑
              this.trackEvent('ad_iframe_click_leave', 'click', {
                'data-ad-slot': this.activeIframe.adSlot,
              });
              this.$logger('ad_iframe_click_leave')
              this.vues.ttTrack(this.activeIframe.adSlot, 'click', 'ad_iframe_click_leave');
            }
          }
        };

        // document.addEventListener(visibilityChangeEvent, onVisibilityChange);
        this.iframeVisibilityTracker = onVisibilityChange;
        document.addEventListener(visibilityChangeEvent, this.iframeVisibilityTracker);
      }

      trackEvent (eventName, eventValue, dataAttributes) {
        // 事件追踪逻辑
        console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

        this.vues.$eventrackFb(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        return; // 如果已加载，直接返回
      }
      this.loadScriptConditionally();
    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd();
        setTimeout(() => {
          this.setupIframeTracking();
        }, 500);
      }, 1500);
    },
    loadScriptConditionally () {
      // 判断广告脚本URL是否存在
      if (!this.adsense?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsense.scriptUrl}"]`);
      if (existingScript) {
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsense?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

      this.setupAdObserver();
    },
    displayAd () {
      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      const adPositions = [
        ...this.themeConfig.adsPosPC,
      ];
      // 对每个位置加载一次广告
      adPositions.forEach(() => {
        console.log('load ads by push...');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });

      // (window.adsbygoogle = window.adsbygoogle || []).push({});
      // (window.adsbygoogle = window.adsbygoogle || []).push({});
      this.$nextTick(() => {
        this.observeAdStatus();
      });
    },
    observeAdStatus () {
      //TODO: 待验证
      let _this = this;
      const adPositions = this.themeConfig.adsPosPC;
      const adElements = adPositions.map(pos => {
        const adContainer = _this.$refs[`ads_pos_${pos}`];
        console.log('adContainer:', adContainer);
        if (adContainer && adContainer[0] && adContainer[0].$el) {
          return adContainer[0].$el.querySelector('ins');
        }
        return null;
      });

      const observerCallback = (mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'data-ad-status') {
            const target = mutation.target;
            const status = target.getAttribute('data-ad-status');
            const positionIndex = adElements.indexOf(target);

            if (positionIndex !== -1) {
              this.$set(this.adStatus, `ad${positionIndex + 1}Visible`, status === 'filled');
            }

            this.adIntersectionObserver.observe(target);
          }
        });
      };

      this.adStatusObserver = new MutationObserver(observerCallback);

      this.adIntersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !entry.target.dataset.viewed) {
              console.log('Ad viewed', entry.target);
              entry.target.dataset.viewed = 'true';
              console.log(entry.target.dataset.adSlot);
              _this.$eventrackFb('ad_fill_viewed', 'expose', {
                'data-ad-slot': entry.target.dataset.adSlot,
              });
              // _this.ttTrack(entry.target.dataset.adSlot, 'expose', 'ad_fill_viewed');
              this.adIntersectionObserver.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.5 } // 至少50%的广告内容在视口中时触发
      );

      adElements.forEach((adElement) => {
        if (adElement) {
          console.log(adElement);
          this.adStatusObserver.observe(adElement, {
            attributes: true,
          });
        }
      });
    },
    setupAdObserver () {
      let _this = this;
      console.log('监听广告的加载状态');
      //设置 MutationObserver 监听特定元素
      const observerCallback = (mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
            // 检查是否是广告相关的 DOM 变更
            if (mutation.target.matches('ins.adsbygoogle')) {
              console.log("AdSense ad loaded");
              _this.$eventrackFb('ad_load_success', 'load', {
                'data-ad-slot': mutation.target.dataset.adSlot,
              });
            }
          }
        });
      }

      // 观察器的配置（观察子节点变动）
      const config = { childList: true, subtree: true };

      this.adDomObserver = new MutationObserver(observerCallback);
      // 开始观察当前组件根元素下的所有变化
      this.$refs.homme && this.adDomObserver.observe(this.$refs.homme, config);
    },

    goLanding (id) {
      console.log(id);
      this.$eventrackFb('news_goto_detail', 'click', {
        id
      });
      // this.$router.push({ name: 'landing', query: { id, thme: this.themeConfig.landType } });
      // 获取当前的 channel 参数
      const channel = this.$route.params.channel;

      // 判断 channel 是否为空，决定跳转路径
      const path = channel ? `/${channel}/landing` : `/landing`;

      // 执行路由跳转
      this.$router.push({ path, query: { id, theme: this.themeConfig.landType } });
    },

  },
}
</script>
